
import { defineComponent } from "vue";
import BaseSearch from "@/components/BaseSearch.vue";
import UrgentList from "@/components/urgent/List.vue";
import { UrgentListType } from "@/components/urgent/urgent";
import { UrgentListTypeFromJson } from "@/components/urgent/utils";
import { useStore } from "vuex";
import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css";
import LoadingIcon from "icons/Loading.vue";

export default defineComponent({
  name: "UrgentSearch",
  components: {
    UrgentList,
    InfiniteLoading,
    LoadingIcon,
    BaseSearch,
  },
  data() {
    const store = useStore();
    return {
      store,
      keyword: "",
      isLoading: false,
      page: 1,
      infiniteId: +new Date(),
      urgents: [] as UrgentListType,
    };
  },
  computed: {
    category(): string {
      return this.store.getters["urgent/selectedTabName"];
    },
  },
  methods: {
    submit() {
      this.page = 1;
      this.urgents = [];
      this.infiniteId += 1;
    },
    infiniteHandler($state: any) {
      this.reloadUrgents()
        .then((res: any) => {
          $state.loaded();
          if (this.urgents.length >= res.data.count) {
            $state.complete();
          }
          this.page += 1;
        })
        .catch(() => {
          $state.loaded();
          $state.complete();
        });
    },
    reloadUrgents() {
      if (this.keyword) {
        this.$pushGAEvent("search", {
          type: "urgent",
          announcement_category: this.category,
          search_keyword: this.keyword,
        });
      }
      this.isLoading = true;
      return this.$axios
        .get("/urgent", {
          params: {
            page: this.page,
            keyword: this.keyword,
            category: this.category,
          },
        })
        .then((res) => {
          const responseData = UrgentListTypeFromJson(res.data.results);
          if (responseData.length > 0) {
            if (this.page == 1) {
              this.urgents = responseData;
            } else {
              this.urgents = this.urgents.concat(responseData);
            }
          }
          this.isLoading = false;
          return res;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
  },
});
